import React from 'react';
import ProjectCard from "./ProjectCard";
import logo from '../res/brand_dark.png';
import {Link} from "react-router-dom";

function ProjectsBlock() {
    return (
        <div className={"block-projects"} id={"block-projects"}>
            <div className={"resizeable-container"}>
                <h2 className={"header-subtitle"}>Our Projects</h2>
                <div>
                    <ProjectCard projectLink={"/projects/speakgpt"} projectBackground={"https://teslasoft.org/res/new/sp_cover.png"} projectIcon={"https://teslasoft.org/res/new/speakgpt.webp"} projectTitle={"SpeakGPT"} projectDescription={"SpeakGPT is an advanced, highly intuitive AI-powered chatbot and voice assistant that harnesses the advanced capabilities of OpenAI's ChatGPT and DALL·E technologies."} side={"left"}/>
                    <ProjectCard projectLink={"/projects/ivisit"} projectBackground={"https://teslasoft.org/res/new/kosice.jpg"} projectIcon={"https://teslasoft.org/res/new/ivisit.png"} projectTitle={"iVisit"} projectDescription={"Discover cities like never before with iVisit, your ultimate travel companion in the palm of your hand. This user-friendly app brings your adventures to life with live suggestions and detailed insights into must-visit spots."} side={"right"}/>
                    <ProjectCard projectLink={"/projects/teslasoft-id"} projectBackground={"https://teslasoft.org/res/new/app_banner.webp"} projectIcon={logo} projectTitle={"Teslasoft ID"} projectDescription={"Simplify your digital life with Teslasoft ID, a seamless Single Sign-On (SSO) solution that lets you access all your accounts with just one login."} side={"left"}/>
                </div>
                <div style={{
                    width: "100%",
                    display: "flex",
                    paddingBottom: "48px",
                    paddingTop: "16px",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <Link to={"/projects"}>
                        <button style={{
                            height: "56px",
                            padding: "12px 8px",
                            fontSize: "14pt",
                            borderRadius: "32px",
                        }} className={"view-all-projects btn-material-filled btn-material"}>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                <p style={{
                                    padding: "0",
                                    margin: "0",
                                    transform: "translateY(1px)",
                                }}>&nbsp;&nbsp;&nbsp;View all projects&nbsp;</p>
                                <span style={{
                                    fontSize: "24pt",
                                }} className={"material-symbols-outlined"}>
                                    keyboard_arrow_right
                                </span>
                            </div>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default ProjectsBlock;