import React from 'react';
import Header from "../../blocks/Header";
import Footer from "../../blocks/Footer";
import ProjectBlockTemplate from "../../blocks/ProjectBlockTemplate";
import speakgpt_1 from "../../res/speakgpt_1.png";
import speakgpt_2 from "../../res/speakgpt_2.png";
import speakgpt_3 from "../../res/speakgpt_3.png";
import speakgpt_4 from "../../res/speakgpt_4.png";
import speakgpt_5 from "../../res/speakgpt_5.png";
import speakgpt_7 from "../../res/speakgpt_7.png";
import speakgpt_8 from "../../res/speakgpt_8.png";
import speakgpt_9 from "../../res/speakgpt_9.png";
import speakgpt_10 from "../../res/speakgpt_10.png";
import speakgpt_11 from "../../res/speakgpt_11.png";
import speakgpt_12 from "../../res/speakgpt_12.png";

function SpeakGpt() {
    return (
        <div>
            <Header/>
            <ProjectBlockTemplate title={"SpeakGPT"}
                                  cover={"https://teslasoft.org/res/new/sp_cover.png"}
                                  icon={"https://teslasoft.org/res/new/speakgpt.webp"}
                                  desc={""}>
                <p>
                    SpeakGPT is an advanced and highly intuitive AI open-source chatbot and voice assistant based on different large language models to provide you with unparalleled performance and functionality. This state-of-the-art voice assistant is designed to cater to your every need, whether you require assistance with writing creative stories, learning new and interesting facts, or performing device routines. Officially SpeakGPT supports GPT models, LLAMA, MIXTRAL, GEMMA, Gemini (regular and pro), Vision, DALL-E and more. You can use others models and API endpoints and provide your feedback.
                </p>
                <h3>Features</h3>
                <ul>
                    <li>Chat (chat history is saved locally and can be imported/exported if needed)</li>
                    <li>Images generation (DALL-e)</li>
                    <li>GPT 4 Vision access (use your photos and images with GPT)</li>
                    <li>Custom AI models (fine-tuned) are supported</li>
                    <li>Activation prompt</li>
                    <li>System prompt</li>
                    <li>Assistant</li>
                    <li>SpeakGPT in context menu</li>
                    <li>SpeakGPT in Share sheet</li>
                    <li>Prompts store</li>
                    <li>A lot of different models (GPT 4 is supported!)</li>
                    <li>No annoying CAPTCHAs and paywalls</li>
                    <li>Pay as you go system</li>
                    <li>Tips for newbies</li>
                    <li>Advanced support</li>
                    <li>Different API providers</li>
                    <li>Set LLM params like temperature, top_p, frequencyPenalty, presencePenalty and logit_bias</li>
                    <li>Compatible with OpenRouter</li>
                </ul>
                <h3>Download and run</h3>
                <br/>
                <div>
                    <a href={"https://assistant.teslasoft.org"}>
                        <button style={{
                            marginBottom: "16px",
                            marginRight: "16px"
                        }} className={"btn-material-filled btn-material"}>Launch desktop version</button>
                    </a>
                    <a href={"https://play.google.com/store/apps/details?id=org.teslasoft.assistant"}>
                        <button style={{
                            marginBottom: "16px",
                            marginRight: "16px"
                        }} className={"btn-material-filled btn-material"}>Download mobile app</button>
                    </a>
                    <a href={"https://github.com/AndraxDev/speak-gpt"}>
                        <button style={{
                            marginBottom: "16px",
                            marginRight: "16px"
                        }} className={"btn-material-filled btn-material"}>View GitHub repository</button>
                    </a>
                </div>
                <h3>Desktop screenshots</h3>
                <br/>
                <img className={"wide-screenshot"} alt={"Screenshot"} src={speakgpt_1}/>
                <br/><br/>
                <img className={"wide-screenshot"} alt={"Screenshot"} src={speakgpt_2}/>
                <br/><br/>
                <img className={"wide-screenshot"} alt={"Screenshot"} src={speakgpt_3}/>
                <br/><br/>
                <img className={"wide-screenshot"} alt={"Screenshot"} src={speakgpt_4}/>
                <br/><br/>
                <img className={"wide-screenshot"} alt={"Screenshot"} src={speakgpt_5}/>
                <br/><br/>
                <h3>Mobile screenshots</h3>
                <br/>
                <div className={"horizontal-scroller"}>
                    <img className={"mobile-screenshot"} alt={"Screenshot"} src={speakgpt_7}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <img className={"mobile-screenshot"} alt={"Screenshot"} src={speakgpt_8}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <img className={"mobile-screenshot"} alt={"Screenshot"} src={speakgpt_9}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <img className={"mobile-screenshot"} alt={"Screenshot"} src={speakgpt_10}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <img className={"mobile-screenshot"} alt={"Screenshot"} src={speakgpt_11}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <img className={"mobile-screenshot"} alt={"Screenshot"} src={speakgpt_12}/>
                </div>
            </ProjectBlockTemplate>
            <Footer/>
        </div>
    );
}

export default SpeakGpt;