import React from 'react';
import Header from "../../blocks/Header";
import ProjectBlockTemplate from "../../blocks/ProjectBlockTemplate";
import tcore_1 from "../../res/tcore_1.webp";
import tcore_2 from "../../res/tcore_2.webp";
import tcore_3 from "../../res/tcore_3.webp";
import tcore_4 from "../../res/tcore_4.webp";
import Footer from "../../blocks/Footer";
import logo from "../../res/brand_dark.png";

function TeslasoftId() {
    return (
        <div>
            <Header/>
            <ProjectBlockTemplate title={"Teslasoft ID"}
                                  cover={"https://teslasoft.org/res/new/app_banner.webp"}
                                  icon={logo}
                                  desc={""}>
                <p>
                    Say goodbye to the hassle of managing multiple logins! Teslasoft ID is a simple Single Sign-On (SSO) system that allows you to access all of your accounts with one login. Security is top priority, so your account is protected with Two-Factor Authentication (2FA) and NFC ID. With Teslasoft ID, you can use your account from anywhere, anytime, with peace of mind knowing your information is safe. Get started today and simplify your online experience!
                </p>
                <h3>Features</h3>
                <ul>
                    <li>Single Sign On</li>
                    <li>Passwordless authentication</li>
                    <li>You can simultaneously sign in to multiple accounts and easily switch between them</li>
                    <li>Two-factor authentication using Google Authenticator, SmartCard or passkey.</li>
                    <li>256-bit encryption</li>
                    <li>Easy implementation</li>
                    <li>Custom callback</li>
                    <li>JWT supported</li>
                </ul>
                <h3>Download and run</h3>
                <br/>
                <div>
                    <a href={"https://play.google.com/store/apps/details?id=com.teslasoft.libraries.support"}>
                        <button style={{
                            marginBottom: "16px",
                            marginRight: "16px"
                        }} className={"btn-material-filled btn-material"}>Download mobile app</button>
                    </a>
                </div>
                <h3>Screenshots</h3>
                <br/>
                <div className={"horizontal-scroller"}>
                    <img className={"mobile-screenshot"} alt={"Screenshot"} src={tcore_1}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <img className={"mobile-screenshot"} alt={"Screenshot"} src={tcore_2}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <img className={"mobile-screenshot"} alt={"Screenshot"} src={tcore_3}/>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <img className={"mobile-screenshot"} alt={"Screenshot"} src={tcore_4}/>
                </div>
            </ProjectBlockTemplate>
            <Footer/>
        </div>
    );
}

export default TeslasoftId;